html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  font-size: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  body {
    background-image: repeating-linear-gradient(-30deg, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 24px, rgba(132, 53, 154, 0.4) 28px), linear-gradient(#6a238d, #9a40a0);
  }
}

@media (max-width: 768px) {
  body {
    background-image: repeating-linear-gradient(-30deg, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 24px, rgba(117, 92, 165, 0.4) 28px), linear-gradient(#5f4f9c, #926bb0);
  }
}